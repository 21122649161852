import "../../../../../wp/wp-includes/css/dist/block-library/style.min.css";
import "tiny-slider/src/tiny-slider.scss";

import PhotoSwipe from "photoswipe/dist/photoswipe.js";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.js";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

function initializePhotoSwipe()
{
    let galleries = document.querySelectorAll('.wp-block-gallery');

    if(galleries)
    {
        galleries.forEach(gallery =>
        {
            gallery.setAttribute('data-photoswipe', '');

            let imageLinks = gallery.querySelectorAll('a');

            if(imageLinks)
            {
                imageLinks.forEach(link =>
                {
                    let img = link.querySelector('img');

                    let width = img.naturalWidth;
                    let height = img.naturalHeight;
                    let url = img.currentSrc;
                    let caption = img.alt;

                    link.classList.add('photo-link');
                    link.setAttribute('data-width', width);
                    link.setAttribute('data-height', height);
                    link.setAttribute('data-large-url', url);
                    link.setAttribute('data-caption', caption);
                });
            }
        });
    }

    Array
        .from(document.querySelectorAll("[data-photoswipe]"))
        .forEach(gallery => initializeGallery(gallery));
}

function initializeGallery(container)
{
    const items = [];
    const pswp = document.querySelector(".pswp");
    const photoLinks = container.querySelectorAll(".photo-link");

    const openGallery = (evt, launchFrom) =>
    {
        const gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, items, {

            index: launchFrom,
            showHideOpacity: true,

            getThumbBoundsFn(index)
            {
                const photo = photoLinks[index];
                const {top, left, height, width} = photo.getBoundingClientRect();

                return {
                    x: left,
                    y: top + (document.scrollingElement || document.documentElement).scrollTop,
                    w: width,
                    h: height
                };
            }

        });
        gallery.init();

        evt.preventDefault();
        evt.stopPropagation();
    };

    photoLinks.forEach((photo, index) =>
    {
        const height = parseInt(photo.dataset.height);
        const width = parseInt(photo.dataset.width);
        const src = photo.dataset.largeUrl;
        const caption = photo.dataset.caption;

        items.push({
            src: src,
            w: width,
            h: height,
            title: caption
        });

        photo.addEventListener("click", evt => openGallery(evt, index));
    });
}

window.addEventListener("load", () =>
{
    setTimeout(function(){
        initializePhotoSwipe();
    }, 250);
});
